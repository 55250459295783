import React from 'react'
import { LoginAsV2 } from '@shared/components/login-as-v2'
import { AppContent } from '@shared/components/sections/app'
import { Spoiler } from '@shared/components/spoiler'
import { ExternalPaths, Paths } from '@shared/menu-items/paths'
import SkSmall from '@shared/svg/SK-small.svg'
import { useRegions } from 'regions/provider'
import styled, { CSSObject } from 'styled-components'

import styles from './style.module.scss'

export const RowDescriptionApp = (): JSX.Element => {
	const regions = useRegions()

	return (
		<>
			<ViewRoot>
				<ViewColText>
					<Text>
						Мы используем Cookies для сбора обезличенных данных. Они полезны для статистики, анализа
						трафика и настройки подходящей рекламы. Оставаясь на сайте, вы соглашаетесь на сбор
						таких данных.
						<br />
						<br />
					</Text>
					<Text>
						Под кредитом понимаются экономические отношения, которые заключаются в получении
						заёмщиком от кредитора денежных средств на условиях возврата и платности, в том числе по
						договору займа.
						<br />
						<br />
					</Text>
					<SpoilerBlock>
						<Spoiler buttonText='Варианты выдачи'>
							{/* 1.{' '}
							<a
								className={styles.link}
								target='_blank'
								rel='noopener noreferrer'
								href={ExternalPaths.dvb}
							>
								АО «Дальневосточный банк»
							</a>
							, лицензия Банка России № 843 от 16.10.2020.
							<br />
							<br /> */}
							1.{' '}
							<a
								className={styles.link}
								target='_blank'
								rel='noopener noreferrer'
								href={ExternalPaths.mk}
							>
								ООО МКК «Магазин кредитов»
							</a>{' '}
							ИНН 6678014749, регистрационный номер записи в реестре ЦБ РФ 651303465.
							<br />
							<br />
							2.{' '}
							<a
								className={styles.link}
								target='_blank'
								rel='noopener noreferrer'
								href={ExternalPaths.loanfund}
							>
								КПК «Фонд Ипотечного Кредитования»
							</a>{' '}
							ИНН 6678102434, запись в реестре ЦБ РФ от 07.06.2019.
							<br />
							<br />
							3. Инвесторы через оператора инвестиционной платформы ООО «Кредит.Клаб» ИНН
							6678105594. Протокол Ассоциации операторов инвестиционных платформ №30-06/20 от 30
							июня 2020 года. Профильные поднадзорные Банку России финансовые организации
							осуществляют самостоятельную деятельность в соответствии с федеральным
							законодательством. Оператор инвестиционной платформы ООО «Кредит.Клаб» осуществляет
							комплексное информационно-техническое обслуживание финансовых организаций, инвесторов
							и заемщиков.
						</Spoiler>
						<br />
						<div>
							<div className={styles.cityRow}>
								<span>
									© 2019—{new Date().getFullYear()} ООО «Кредит.Клаб»
									<br />
									ОГРН 1196658084743
									<br />
									ИНН 6678105594
									<br />
								</span>
								<span
									className={styles.currentCity}
									suppressHydrationWarning
									onClick={regions.openPicker}
								>
									{regions.currentCity?.displayName}
								</span>
							</div>
							<a className={'main-link'} href='mailto:platform@credit.club '>
								platform@credit.club
							</a>
							<br />
							<br />
							<a className={styles.link} href={Paths.sitemap}>
								Карта сайта
							</a>
						</div>
					</SpoilerBlock>
				</ViewColText>
				<ViewColLogos>
					<ViewCellLogin>
						<LoginAsV2 />
					</ViewCellLogin>
					<div>
						<AppContent
							TitleComponent={<AppTitle>Мобильное приложение Credit.Club Деньги</AppTitle>}
							descriptionStyles={styles.appDesc}
						/>
					</div>
					<LogosBlock>
						<LinkLogo
							href={ExternalPaths.skolkovo}
							target='_blank'
							rel='noopener noreferrer'
							className={styles.logosItem}
						>
							<ImageSk />
							<SkText>
								Участник проекта инновационного
								<br /> центра «Сколково»
							</SkText>
						</LinkLogo>
					</LogosBlock>
				</ViewColLogos>
			</ViewRoot>
		</>
	)
}

const ImageSk = styled(SkSmall)({
	width: 46,
	height: 'auto',
})

const LinkLogo = styled.a(({ theme }) => ({
	...theme.typography.text14_18,
	display: 'flex',
	gap: 16,
	justifyContent: 'flex-start',
	alignItems: 'center',
}))

const ViewColLogos = styled.div(
	() =>
		({
			display: 'flex',
			gap: 32,
			flexDirection: 'column',
			gridArea: 'app',
		} as CSSObject)
)

const ViewColText = styled.div({
	paddingRight: 32,
	gridArea: 'spoiler',
})

const Text = styled.p(({ theme }) => ({
	...theme.typography.text12_16,
	[theme.mediaQueries.mediaMinWidthMobile]: {
		...theme.typography.text14_18,
	},
}))

const SpoilerBlock = styled.div(({ theme }) => ({
	...theme.typography.text12_16,
	marginTop: 0,
	[theme.mediaQueries.mediaMinWidthMobile]: {
		...theme.typography.text14_18,
	},
}))

const ViewRoot = styled.div(({ theme }) => ({
	display: 'grid',
	color: 'rgba(0,0,0,0.5)',
	gridTemplateColumns: '1fr',
	gridRowGap: 64,
	gridTemplateAreas: '"app" "spoiler"',
	[theme.mediaQueries.mediaMinWidthTablet]: {
		gridTemplateAreas: '"spoiler app"',
		gridTemplateColumns: '1fr 1fr',
	},
}))

const ViewCellLogin = styled.div({
	display: 'block',
})

const SkText = styled.div(
	() =>
		({
			whiteSpace: 'nowrap',
		} as CSSObject)
)

const LogosBlock = styled.div(
	({ theme }) =>
		({
			flexWrap: 'nowrap',
			display: 'flex',
			flexDirection: 'column',
			gap: 24,

			[theme.mediaQueries.mediaMinWidthExtraMobile]: {
				flexDirection: 'row',
			},
		} as CSSObject)
)

const AppTitle = styled.div(({ theme }) => ({
	fontSize: 18,
	color: theme.colors.mainblack,
	marginBottom: -16,
}))
